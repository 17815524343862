import { HomeProfile } from "@/interfaces/enums/HomeProfile"
import { create } from "zustand"

type Store = {
  profile: HomeProfile
  setProfile: (value: HomeProfile) => void
  isApplicant: () => boolean
  isEmployer: () => boolean
  toggleProfile: () => void
}

export const useHomeProfileStore = create<Store>((setState, getState) => ({
  profile: HomeProfile.Applicant,
  setProfile: (value) => setState(() => ({ profile: value })),
  isApplicant: () => getState().profile === HomeProfile.Applicant,
  isEmployer: () => getState().profile === HomeProfile.Employer,
  toggleProfile: () =>
    setState(() => ({
      profile: getState().isApplicant() ? HomeProfile.Employer : HomeProfile.Applicant,
    })),
}))
