import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"

export default function useScreenSize() {
  const theme = useTheme()

  const isXs = useMediaQuery(theme.breakpoints.only("xs"))
  const isSm = useMediaQuery(theme.breakpoints.only("sm"))
  const isMd = useMediaQuery(theme.breakpoints.only("md"))
  const isLg = useMediaQuery(theme.breakpoints.only("lg"))
  const isXl = useMediaQuery(theme.breakpoints.only("xl"))
  const isUpSm = useMediaQuery(theme.breakpoints.up("sm"))
  const isUpMd = useMediaQuery(theme.breakpoints.up("md"))
  const isDownSm = useMediaQuery(theme.breakpoints.down("sm"))
  const isDownMd = useMediaQuery(theme.breakpoints.down("md"))

  return {
    isXs,
    isSm,
    isMd,
    isLg,
    isXl,
    isUpSm,
    isUpMd,
    isDownSm,
    isDownMd,
  }
}
