"use client"

import { RoleButtons } from "@/components/ui/button/RoleButtons"
import SearchBar from "@/containers/home-page/SearchBar"
import useScreenSize from "@/hooks/useScreenSize"
import { ProfileOutput } from "@/interfaces/profile/ProfileOutput"
import { useHomeProfileStore } from "@/stores/ui/useHomeProfileStore"
import { getAssetsPath } from "@/utils/getAssetsPathUtils"
import { Box, Fade, Typography } from "@mui/material"
import Image from "next/image"

interface HeroSectionProps {
  profiles?: ProfileOutput[]
}

export default function HeroSection({ profiles }: HeroSectionProps) {
  const { isApplicant, isEmployer } = useHomeProfileStore()
  const { isXs } = useScreenSize()

  const getBannerSrc = (type: "applicant" | "employer"): string => {
    if (type === "applicant") {
      return isXs
        ? getAssetsPath() + "/img/novo-home-trabalhador-mobile.webp"
        : getAssetsPath() + "/img/novo-home-trabalhador.webp"
    } else if (type === "employer") {
      return getAssetsPath() + "/img/banner-home-empresa.webp"
    } else {
      return ""
    }
  }

  return (
    <>
      {profiles && profiles.length > 0 && (
        <RoleButtons
          profiles={profiles}
          sx={{
            margin: 1,
            justifyContent: "center",
            color: "blueMsGov.main",
            backgroundColor: "white",
            borderColor: "white",
          }}
        />
      )}
      <Box component="section" sx={{ width: "100%" }}>
        <Box
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            height: { xs: "475px", lg: "500px", xl: "550px" },
            borderRadius: "1.5rem",
            boxShadow: 5,
            padding: 2,
            my: 0,
          }}
        >
          <Fade in={isApplicant()} timeout={300} appear={false} exit>
            <Image
              alt="banner da página para candidatos"
              src={getBannerSrc("applicant")}
              unoptimized
              fill
              priority
              style={{
                objectFit: "cover",
                objectPosition: "28% 50%",
                borderRadius: "inherit",
              }}
            />
          </Fade>
          <Fade in={isEmployer()} timeout={300} appear={false} exit>
            <Image
              alt="banner da página para empresas"
              src={getBannerSrc("employer")}
              unoptimized
              fill
              priority
              style={{
                objectFit: "cover",
                objectPosition: "65% 50%",
                borderRadius: "inherit",
              }}
            />
          </Fade>

          {isApplicant() && (
            <>
              <Box
                sx={{
                  position: "absolute",
                  top: {
                    xs: "3%",
                    md: "5%",
                  },
                  left: {
                    xs: "0.025%",
                    md: "8%",
                  },
                  zIndex: 1,
                  padding: "2%",
                  width: {
                    xs: "10rem",
                    md: "13rem",
                    lg: "15rem",
                    xl: "17rem",
                  },
                  height: "auto",
                }}
              >
                <Image
                  alt="logo do ms qualifica"
                  src={getAssetsPath() + "/img/logo-ms-qualifica.svg"}
                  width={0}
                  height={0}
                  priority={true}
                  style={{
                    objectFit: "contain",
                    width: "100%",
                    height: "auto",
                  }}
                />
              </Box>

              <Box
                sx={{
                  position: "absolute",
                  bottom: { xs: "20%", sm: "15%" },
                  minWidth: {
                    sm: "250px",
                  },
                }}
              >
                <SearchBar />
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  width: {
                    xs: "15rem",
                    md: "18rem",
                    lg: "20rem",
                    xl: "22rem",
                  },
                  height: "auto",
                  right: {
                    sm: "4%",
                  },
                  bottom: { xs: "3%", sm: "6%" },
                }}
              >
                <Image
                  alt="logos do governo de mato grosso do sul e funtrab"
                  src={getAssetsPath() + "/img/logos-governo-funtrab-branco.svg"}
                  width={0}
                  height={0}
                  style={{
                    objectFit: "contain",
                    width: "100%",
                    height: "auto",
                  }}
                />
              </Box>
            </>
          )}
          {isEmployer() && (
            <>
              <Box
                sx={{
                  position: "absolute",
                  left: { xs: "5%", lg: "10%" },
                  top: {
                    xs: "23%",
                    sm: "27%",
                    lg: "20%",
                  },
                  maxWidth: { sm: "70%", md: "50%", lg: "40%" },
                  marginX: "1rem",
                }}
              >
                <Box
                  sx={{
                    width: {
                      xs: "9rem",
                      sm: "10rem",
                      md: "12rem",
                      lg: "14rem",
                      xl: "16rem",
                    },
                    marginBottom: "1rem",
                  }}
                >
                  <Image
                    alt="logo do ms qualifica"
                    src={getAssetsPath() + "/img/logo-ms-qualifica-branco.svg"}
                    width={0}
                    height={0}
                    unoptimized
                    style={{
                      objectFit: "contain",
                      width: "100%",
                      height: "auto",
                    }}
                  />
                </Box>

                <Typography
                  variant="h6"
                  sx={{
                    fontSize: { xs: "1.4em", lg: "1.7em" },
                    lineHeight: "35px",
                    width: "95%",
                  }}
                >
                  <span
                    style={{
                      backgroundColor: "white",
                      paddingLeft: "3px",
                      paddingRight: "3px",
                      boxDecorationBreak: "clone",
                      WebkitBoxDecorationBreak: "clone",
                    }}
                  >
                    Impulsione a sua empresa com profissionais qualificados
                  </span>
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: {
                      lg: "1em",
                    },
                    color: "white",
                    textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    marginTop: "0.5rem",
                  }}
                >
                  Encontre milhares de currículos e agilize o processo de seleção com o MS
                  Qualifica Digital.
                </Typography>
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  width: {
                    xs: "15rem",
                    sm: "18rem",
                    md: "18rem",
                    lg: "20rem",
                    xl: "22rem",
                  },
                  left: { md: "5%", lg: "10%" },
                  bottom: { xs: "3%", sm: "5%", lg: "7%" },
                  marginX: { md: "1rem" },
                }}
              >
                <Image
                  alt="logos do governo de mato grosso do sul e funtrab"
                  src={getAssetsPath() + "/img/logos-governo-funtrab-branco.svg"}
                  width={0}
                  height={0}
                  style={{
                    objectFit: "contain",
                    width: "100%",
                    height: "auto",
                  }}
                />
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  )
}
