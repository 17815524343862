import { Stack } from "@mui/material"
import React from "react"

export function UserInformationWrapper({ children }: { children: React.ReactNode }) {
  return (
    <Stack
      direction="row"
      columnGap="1rem"
      alignItems="center"
      justifyContent="end"
      sx={{ color: "black", margin: "0.75rem", marginRight: "auto" }}
    >
      {children}
    </Stack>
  )
}
