import Cookies from "js-cookie"

const GOV_STATE = "state_gov"

export const setStateGov = (state: string) => {
  Cookies.set(GOV_STATE, state)
}

export const getStateGov = (): string | undefined => {
  return Cookies.get(GOV_STATE)
}

export const removeStateGov = () => {
  Cookies.remove(GOV_STATE)
}
