import { Box } from "@mui/material"

import { ProfilePicture } from "./ProfilePicture"
import { UserInformation } from "./UserInformation"
import { UserInformationWrapper } from "./UserInformationWrapper"

export function UserProfile() {
  return (
    <Box>
      <UserInformationWrapper>
        <UserInformation sx={{ display: { xs: "none", md: "block" }, color: "white" }} />
        <ProfilePicture />
      </UserInformationWrapper>
    </Box>
  )
}
