import { ROUTES } from "@/interfaces/constants/routes"
import { Box, Stack } from "@mui/material"

import { HomeHeaderLink } from "./HomeHeaderLink"

export function HomeHeaderLinks() {
  return (
    <>
      <Box
        bgcolor={"white"}
        paddingX={1}
        paddingY={2}
        display={{ xs: "none", sm: "block" }}
      >
        <Stack
          spacing={2}
          direction={{ xs: "column", sm: "row" }}
          sx={{
            alignItems: "center",
            justifyContent: { xs: "center", sm: "space-between" },
            maxWidth: "lg",
            margin: "auto",
          }}
        >
          <Stack
            spacing={2}
            direction={{ xs: "column", sm: "row" }}
            minWidth="115px"
            textAlign="start"
          >
            <HomeHeaderLink href="#home-content" underline="hover">
              IR PARA CONTEÚDO
            </HomeHeaderLink>
            <HomeHeaderLink href="#home-nav" underline="hover">
              IR PARA NAVEGAÇÃO
            </HomeHeaderLink>
            <HomeHeaderLink href={ROUTES.HOME_BUSCAVAGAS} underline="hover">
              IR PARA BUSCAS
            </HomeHeaderLink>
          </Stack>
          <Stack
            spacing={2}
            direction={{ xs: "column", sm: "row" }}
            minWidth="115px"
            textAlign="start"
          >
            <HomeHeaderLink
              href="http://www.ouvidorias.ms.gov.br/publico/Manifestacao/RegistrarManifestacao.aspx"
              underline="hover"
              target="_blank"
            >
              OUVIDORIA
            </HomeHeaderLink>
            <HomeHeaderLink
              href="http://www.transparencia.ms.gov.br/"
              target="_blank"
              underline="hover"
            >
              TRANSPARÊNCIA
            </HomeHeaderLink>
          </Stack>
        </Stack>
      </Box>
    </>
  )
}
