import { UserRoles } from "@/interfaces/enums/UserRoles"

import { ROUTES } from "./routes"

export interface UserRoleAttributes {
  weight: number
  description: string
  defaultPanel: string
}
export const userRoleConsts: Readonly<Record<UserRoles, UserRoleAttributes>> = {
  [UserRoles.ADMIN]: {
    weight: 10,
    description: "Administrador do Sistema",
    defaultPanel: ROUTES.PAINEL.ADMIN,
  },
  [UserRoles.COMPANY]: {
    weight: 4,
    description: "Colaborador de Empresa",
    defaultPanel: ROUTES.PAINEL.COMPANY,
  },
  [UserRoles.CANDIDATE]: {
    weight: 2,
    description: "Pessoa",
    defaultPanel: ROUTES.PAINEL.CANDIDATE,
  },
  [UserRoles.STUDENT]: {
    weight: 3,
    description: "Pessoa com perfil de qualificação",
    defaultPanel: ROUTES.PAINEL.STUDENT,
  },
  [UserRoles.FUNTRAB]: {
    weight: 6,
    description: "Colaborador FUNTRAB",
    defaultPanel: ROUTES.PAINEL.FUNTRAB,
  },
  [UserRoles.SEMADESC]: {
    weight: 8,
    description: "Colaborador SEMADESC",
    defaultPanel: ROUTES.PAINEL.SEMADESC,
  },
}
