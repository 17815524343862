import { Paper, useTheme } from "@mui/material"

import { ProfilePicture } from "./ProfilePicture"
import { UserInformation } from "./UserInformation"
import { UserInformationWrapper } from "./UserInformationWrapper"

export function MobileUserProfile() {
  const theme = useTheme()

  return (
    <Paper
      square={false}
      elevation={3}
      sx={{
        position: "absolute",
        height: theme.appHeader.height,
        top: 0,
        right: 0,
        margin: "auto 0",
      }}
    >
      <UserInformationWrapper>
        <UserInformation />
        <ProfilePicture sx={{ color: "blueMsGov.main" }} />
      </UserInformationWrapper>
    </Paper>
  )
}
