"use client"

import { ROUTES } from "@/interfaces/constants/routes"
import { Search } from "@mui/icons-material"
import { Box, ButtonBase, FormControl, InputBase } from "@mui/material"
import { useRouter } from "next/navigation"
import { useState } from "react"

export default function SearchBar() {
  const router = useRouter()
  const [searchQuery, setSearchQuery] = useState("")

  const handleSearch = () => {
    if (!router) return // Verificação extra para garantir que o router está disponível
    if (searchQuery.trim() !== "") {
      router.push(`${ROUTES.HOME_BUSCAVAGAS}?query=${searchQuery}`)
    } else {
      router.push(ROUTES.HOME_BUSCAVAGAS)
    }
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch()
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        bgcolor: "#efefef",
        opacity: "85%",
        columnGap: "0.5rem",
        padding: "0.4rem 1.5rem",
        borderRadius: "2rem",
        minWidth: { xs: "13.75rem", md: "18.75rem" },
      }}
    >
      <FormControl
        variant="standard"
        sx={{
          flex: 1,
          padding: 0,
        }}
      >
        <InputBase
          size="small"
          placeholder="Buscar vagas de emprego"
          fullWidth
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onKeyPress={handleKeyPress}
          sx={{
            "& .MuiInputBase-input": {
              border: "1px transparent",
              fontSize: "0.9rem",
              padding: 0,
              color: "rgba(73, 69, 79, 1)",
            },
          }}
        />
      </FormControl>
      <ButtonBase
        onClick={handleSearch}
        sx={{
          padding: "0.25rem",
          borderRadius: "1rem",
          ":hover": { backgroundColor: "#e0e0e0" },
        }}
      >
        <Search
          sx={{
            color: "placeholder.main",
            zIndex: 1000,
            fontSize: "1.25rem",
          }}
        />
      </ButtonBase>
    </Box>
  )
}
