import { PersonBase } from "@/interfaces/person/PersonBase"
import { CurrentProfile } from "@/interfaces/profile/CurrentProfile"
import { create } from "zustand"
import { createJSONStorage, persist } from "zustand/middleware"

type Store = {
  currentProfile: CurrentProfile | null
  currentPerson: PersonBase | null
  setCurrentProfile: (profile: CurrentProfile | null) => void
  setCurrentPerson: (person: PersonBase) => void
  removeCurrentProfile: () => void
  reset: () => void
}

export const useCurrentProfileStore = create<Store>()(
  persist(
    (set) => ({
      currentProfile: null,
      currentPerson: null,
      setCurrentProfile: (profile) => set({ currentProfile: profile }),
      setCurrentPerson: (person) => set({ currentPerson: person }),
      removeCurrentProfile: () => set({ currentProfile: null }),
      reset: () => set({ currentProfile: null, currentPerson: null }),
    }),
    {
      name: "profile-storage",
      storage: createJSONStorage(() => localStorage),
    },
  ),
)
