import { createSvgIcon } from "@mui/material"

export const FUNTRABIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <g id="Icon Left">
      <path
        id="Vector"
        d="M7.45834 13.9807H11.8333L12.9583 9.94225L8.45833 9.92302C8.45833 9.92302 8.95834 7.88457 9.77084 6.61535C10.75 5.05766 11.6458 4.63459 12.9583 4.13459C13.2917 4.01921 13.9167 3.92305 14.6667 3.86536L15.9167 0C14.7083 0.0384613 13.3125 0.134615 12.1458 0.28846C9.39584 0.653842 7 2.57691 5.5 5.13459C4.25 7.44226 3.02083 13.0576 2.83333 13.8845L0 23.9999H4.70833C4.70833 23.9999 6.9375 15.9999 7.45834 13.9807Z"
        fill=""
      />
      <path
        id="Vector_2"
        d="M24 8.01904H19.4792C19.4792 8.0575 19.3125 8.98058 19.0208 9.98057H14.6042L13.4792 14.019L17.9792 14.0382C17.9792 14.0382 17.4792 16.0767 16.6667 17.3459C15.6875 18.9036 14.7917 19.3267 13.4792 19.8267C12.5833 20.1536 9.54167 20.2305 7.375 20.2305L6.375 23.9997H7.5625C7.5625 23.9997 11.5625 24.019 14.2917 23.6728C17.0417 23.3074 19.2292 21.5574 20.9375 18.8267C22.2917 16.3267 23.625 9.96134 23.625 9.96134C23.75 9.32673 23.875 8.67289 24 8.01904Z"
        fill=""
      />
    </g>
  </svg>,
  "FUNTRABIcon",
)
