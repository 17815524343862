import { ROUTES } from "@/interfaces/constants/routes"
import { Menu as MenuIcon } from "@mui/icons-material"
import { Button, Stack } from "@mui/material"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import { useState } from "react"

import { HomeHeaderLink } from "./HomeHeaderLink"

export default function HomeHeaderMobileLinks() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Stack
      direction="row"
      sx={{
        display: { xs: "flex", sm: "none" },
        backgroundColor: "#003F7E",
        color: "white",
        justifyContent: "center",
        py: 0.25,
      }}
    >
      <Button
        id="navbar-menu-button"
        aria-controls={open ? "navbar-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        sx={{
          color: { xs: "inherit", sm: "primary" },
          maxWidth: "auto",
        }}
        onClick={handleClick}
      >
        <MenuIcon />
      </Button>
      <Menu
        id="navbar-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "navbar-menu-button",
          dense: true,
        }}
      >
        <MenuItem>
          <HomeHeaderLink href="#home-content" underline="hover">
            IR PARA CONTEÚDO
          </HomeHeaderLink>
        </MenuItem>
        <MenuItem>
          <HomeHeaderLink href="#home-nav" underline="hover">
            IR PARA NAVEGAÇÃO
          </HomeHeaderLink>
        </MenuItem>
        <MenuItem>
          <HomeHeaderLink href={ROUTES.HOME_BUSCAVAGAS} underline="hover">
            IR PARA BUSCAS
          </HomeHeaderLink>
        </MenuItem>
        <MenuItem>
          <HomeHeaderLink
            href="http://www.ouvidorias.ms.gov.br/publico/Manifestacao/RegistrarManifestacao.aspx"
            underline="hover"
            target="_blank"
          >
            OUVIDORIA
          </HomeHeaderLink>
        </MenuItem>
        <MenuItem>
          <HomeHeaderLink
            href="http://www.transparencia.ms.gov.br/"
            underline="hover"
            target="_blank"
          >
            TRANSPARÊNCIA
          </HomeHeaderLink>
        </MenuItem>
      </Menu>
    </Stack>
  )
}
