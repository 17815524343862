import { FUNTRABIcon } from "@/components/ui/custom-icon/FUNTRABIcon"
import { MSArmsIcon } from "@/components/ui/custom-icon/MSArmsIcon"
import { UserRoleAttributes, userRoleConsts } from "@/interfaces/constants/UserRoleConsts"
import { UserRoles } from "@/interfaces/enums/UserRoles"
import { CurrentProfile } from "@/interfaces/profile/CurrentProfile"
import { ProfileOutput } from "@/interfaces/profile/ProfileOutput"
import { AdminPanelSettings, School } from "@mui/icons-material"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import WorkIcon from "@mui/icons-material/Work"

export function getProfileIcon(profile: CurrentProfile | null) {
  if (isCandidateProfileRole(profile)) {
    return <AccountCircleIcon />
  } else if (isCompanyProfileRole(profile)) {
    return <WorkIcon />
  } else if (isFuntrabProfileRole(profile)) {
    return <FUNTRABIcon />
  } else if (isSemadescProfileRole(profile)) {
    return <MSArmsIcon />
  }
  return null
}

export const UserRolesIcon: { [key in UserRoles]: JSX.Element } = {
  [UserRoles.ADMIN]: <AdminPanelSettings />,
  [UserRoles.COMPANY]: <WorkIcon />,
  [UserRoles.CANDIDATE]: <AccountCircleIcon />,
  [UserRoles.STUDENT]: <School />,
  [UserRoles.FUNTRAB]: <FUNTRABIcon />,
  [UserRoles.SEMADESC]: <MSArmsIcon />,
}

export function getProfileTextName(profile: CurrentProfile | null) {
  if (isCandidateProfileRole(profile)) {
    return "CANDIDATO"
  } else if (isCompanyProfileRole(profile)) {
    return "EMPRESA"
  } else if (isFuntrabProfileRole(profile)) {
    return "FUNTRAB"
  } else if (isSemadescProfileRole(profile)) {
    return "SEMADESC"
  }
}

export function isCompanyProfileRole(profile: CurrentProfile | null) {
  return profile?.role?.value === UserRoles.COMPANY
}

export function isCandidateProfileRole(profile: CurrentProfile | null) {
  return profile?.role?.value === UserRoles.CANDIDATE
}

export function hasFuntrabProfileRole(profiles: ProfileOutput[] | undefined) {
  return profiles?.some((profile) => profile.role.value === UserRoles.FUNTRAB) || false
}

export function isFuntrabProfileRole(profile: CurrentProfile | null) {
  return profile?.role?.value === UserRoles.FUNTRAB
}

export function hasSemadescProfileRole(profiles: ProfileOutput[] | undefined) {
  return profiles?.some((profile) => profile.role.value === UserRoles.SEMADESC) || false
}

export function isSemadescProfileRole(profile: CurrentProfile | null) {
  return profile?.role?.value === UserRoles.SEMADESC
}

export function getProfileById(
  profiles: CurrentProfile[] | undefined,
  profileId: string = "",
) {
  if (!profiles || !profileId) return null

  return profiles.find((profile) => profile.id == profileId)
}

export function getHighestWeightedRole(
  profiles: ProfileOutput[],
): UserRoleAttributes | null {
  if (profiles.length === 0) return null

  let highestWeightedRole: UserRoles | null = null
  let highestWeight = -Infinity

  profiles.forEach((profile) => {
    const role = profile.role.value as UserRoles
    const weight = userRoleConsts[role]?.weight ?? -Infinity

    if (weight > highestWeight) {
      highestWeightedRole = role
      highestWeight = weight
    }
  })
  if (highestWeightedRole === null) return null
  return userRoleConsts[highestWeightedRole]
}

export function getDefaultPanelOfHighestWeightedRole(
  profiles: ProfileOutput[],
): string | null {
  if (profiles.length === 0) return null

  let highestWeightedRole: UserRoles | null = null
  let highestWeight = -Infinity

  profiles.forEach((profile) => {
    const role = profile.role.value as UserRoles
    const roleAttributes = userRoleConsts[role]
    const weight = roleAttributes?.weight ?? -Infinity
    const defaultPanel = roleAttributes?.defaultPanel

    if (weight > highestWeight && defaultPanel) {
      highestWeightedRole = role
      highestWeight = weight
    }
  })

  if (highestWeightedRole === null) return null

  const highestRoleAttributes = userRoleConsts[highestWeightedRole] as UserRoleAttributes
  if (!highestRoleAttributes) return null

  return highestRoleAttributes.defaultPanel || null
}
