import useScreenSize from "@/hooks/useScreenSize"
import { useUserProfileStore } from "@/stores/ui/useUserProfileStore"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import { IconButton, SxProps } from "@mui/material"

interface ProfilePictureProps {
  sx?: SxProps
}

export function ProfilePicture({ sx }: ProfilePictureProps) {
  const { clicked, setClicked } = useUserProfileStore()
  const { isUpMd } = useScreenSize()

  return (
    <IconButton
      onClick={() => {
        if (!isUpMd) setClicked(!clicked)
      }}
      aria-label="informações do usuário autenticado"
      sx={{ color: "white", ...sx }}
    >
      <AccountCircleIcon
        sx={{ color: "inherit", fontSize: { xs: "3.5rem", sm: "3.5rem" } }}
      />
    </IconButton>
  )
}
