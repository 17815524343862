import { useProfiles } from "@/hooks/useProfiles"
import { logoutService } from "@/services/logout/logoutService"
import { useUserProfileStore } from "@/stores/ui/useUserProfileStore"
import { Button, Stack, SxProps, Typography } from "@mui/material"
import { useRouter } from "next/navigation"

export function UserInformation({ sx }: { sx?: SxProps }) {
  const { person } = useProfiles()
  const { clicked } = useUserProfileStore()
  const router = useRouter()

  return (
    <Stack direction="row" alignItems="right" textAlign="end" sx={{ ...sx }}>
      <Stack direction="column" alignItems="flex-end" spacing={-0.3}>
        <Typography sx={{ margin: 0, padding: 0 }} fontWeight="bold">
          {person?.name}
        </Typography>
        <Typography
          sx={{ margin: 0, padding: 0 }}
          variant="caption"
          color={clicked ? "text.secondary" : "white"}
        >
          {person?.email}
        </Typography>
        <Button
          variant="text"
          color="inherit"
          sx={{
            fontSize: "0.75rem",
            padding: 0,
            margin: 0,
            minWidth: "auto",
            textTransform: "uppercase",
          }}
          onClick={() => logoutService(router.push)}
        >
          Sair
        </Button>
      </Stack>
    </Stack>
  )
}
