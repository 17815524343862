import { Button, ButtonProps, SxProps } from "@mui/material"
import Image from "next/image"

interface ImageButtonProps extends ButtonProps {
  imageSrc: string
  alt: string
  sx?: SxProps
  usePriority?: boolean
}

export function ImageButton({
  imageSrc,
  alt,
  sx,
  usePriority = false,
  ...rest
}: Readonly<ImageButtonProps>) {
  return (
    <Button sx={{ position: "relative", ...sx }} {...rest}>
      <Image
        src={imageSrc}
        alt={alt}
        width={500}
        height={500}
        style={{ width: "100%", height: "100%" }}
        {...(usePriority && { priority: true })}
      />
    </Button>
  )
}
