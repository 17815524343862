import { create } from "zustand"

type Store = {
  clicked: boolean
  setClicked: (value: boolean) => void
}

export const useUserProfileStore = create<Store>((set) => ({
  clicked: false,
  setClicked: (value) => set(() => ({ clicked: value })),
}))
