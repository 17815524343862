import { lato } from "@/styles/fonts"
import { Link, LinkProps, styled } from "@mui/material"

export const HomeHeaderLink = styled(Link)<LinkProps>(({ theme }) => ({
  fontFamily: lato.style.fontFamily,
  fontWeight: 700,
  fontSize: "0.7rem",
  color: "blueMsGov.main",
  textWrap: "nowrap",
  [theme.breakpoints.up("sm")]: {
    fontSize: "0.8em",
  },
}))
