import { PersonBase } from "@/interfaces/person/PersonBase"
import { ProfileOutput } from "@/interfaces/profile/ProfileOutput"
import { useEffect, useState } from "react"
import useSWR, { mutate } from "swr"

import { useAuth } from "./useAuth"

let cachedData: { person: PersonBase | null; profiles: ProfileOutput[] } | null = null

export function useProfiles() {
  const [isHydrated, setIsHydrated] = useState(false)
  const isLoggedIn = useAuth()
  const { data, error, isLoading, isValidating } = useSWR(
    isHydrated && isLoggedIn ? "/user/profile" : null,
    {
      onError: (error) => {
        console.error("Falha ao buscar perfis:", error)
      },
      dedupingInterval: 60000, // 1 minuto de deduplicação
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  )

  useEffect(() => {
    setIsHydrated(true)
  }, [])

  useEffect(() => {
    if (data) {
      cachedData = { person: data.person, profiles: data.profiles }
    }
  }, [data])

  const person: PersonBase | null = data?.person ?? cachedData?.person ?? null
  const profiles: ProfileOutput[] = data?.profiles ?? cachedData?.profiles ?? []

  return {
    person,
    profiles,
    isLoadingProfiles: !isHydrated || isLoading,
    isErrorProfiles: !!error,
    isValidatingProfiles: isValidating,
    mutateProfiles: () => mutate("/user/profile"), // Função para forçar o refetch
  }
}
