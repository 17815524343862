import { IsGovUserLoggedInInput } from "@/interfaces/gov/IsGovUserLoggedInInput"
import { IsGovUserLoggedInOutput } from "@/interfaces/gov/IsGovUserLoggedInOutput"
import axios from "axios"

const api = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_AUTH_GOV_DOMAIN}/api`,
  headers: {
    "Content-Type": "application/json",
  },
})

const authCache = new Map<string, { data: IsGovUserLoggedInOutput; expiry: number }>()

function addToAuthCache(
  token: string,
  data: IsGovUserLoggedInOutput,
  ttl: number = 60000,
): void {
  const expiry = Date.now() + ttl
  authCache.set(token, { data, expiry })
}

function checkAuthCache(token: string): IsGovUserLoggedInOutput | null {
  const cached = authCache.get(token)
  if (cached) {
    if (cached.expiry > Date.now()) {
      return cached.data // Dados do cache ainda são válidos
    } else {
      authCache.delete(token) // Dados do cache expiraram
    }
  }
  return null // Não encontrado ou expirado
}

export async function isGovUserLoggedInWithCache(
  jwtToken: string | undefined,
): Promise<IsGovUserLoggedInOutput> {
  if (!jwtToken) {
    throw new Error("Token JWT não encontrado.")
  }

  // Verificar cache
  const cachedData = checkAuthCache(jwtToken)
  if (cachedData) {
    return cachedData // Retornar dados do cache se válidos
  }

  const body: IsGovUserLoggedInInput = { access_token: jwtToken }

  try {
    const response = await api.post<IsGovUserLoggedInOutput>(`/checar/`, body)

    // Adicionar ao cache após sucesso
    addToAuthCache(jwtToken, response.data)

    return response.data
  } catch (error: unknown) {
    if (error instanceof Error) {
      console.error(`Erro de autenticação: ${error.message}`)
    } else {
      console.error(`Erro desconhecido de autenticação: ${error}`)
    }
    throw error // Repassar o erro para ser tratado pelo chamador
  }
}
