import { getJwtToken } from "@/cookies/jwtCookieHelper"
import { isGovUserLoggedInWithCache } from "@/services/gov/authCacheService"
import { useEffect, useState } from "react"

export function useAuth() {
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  useEffect(() => {
    const checkLoginStatus = async () => {
      const jwtToken = getJwtToken()
      if (!jwtToken) {
        setIsLoggedIn(false)
        return
      }

      try {
        const result = await isGovUserLoggedInWithCache(jwtToken)
        setIsLoggedIn(result !== null)
      } catch (error: unknown) {
        console.debug(
          `Falha ao verificar o status de login. Pode ser uma condição esperada. Detalhes: ${error}`,
        )
        setIsLoggedIn(false)
      }
    }

    checkLoginStatus()
  }, [])

  return isLoggedIn
}
