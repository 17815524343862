import { ROUTES } from "@/interfaces/constants/routes"
import { UserRoles } from "@/interfaces/enums/UserRoles"
import { ProfileOutput } from "@/interfaces/profile/ProfileOutput"
import { UserRolesIcon } from "@/utils/profileUtils"
import { Box, Button, SxProps, Theme } from "@mui/material"
import { useRouter } from "next/navigation"

interface RoleButtonsProps {
  profiles: ProfileOutput[] | undefined
  sx?: SxProps<Theme>
  buttonSx?: SxProps<Theme>
}

export function RoleButtons({ profiles, sx, buttonSx }: RoleButtonsProps) {
  const router = useRouter()

  const handleRedirect = (path: string) => {
    router.push(path)
  }

  const buttons = []

  if (!profiles) return null

  if (profiles.some((profile) => profile.role.value === UserRoles.CANDIDATE)) {
    buttons.push(
      <Button
        startIcon={UserRolesIcon.CANDIDATE}
        key={UserRoles.CANDIDATE}
        variant="contained"
        color="primary"
        onClick={() => handleRedirect(ROUTES.PAINEL.CANDIDATE)}
        sx={{ ...buttonSx }}
        aria-label="Acessar Painel Trabalhador"
      >
        Painel Trabalhador
      </Button>,
    )
  }

  if (profiles.some((profile) => profile.role.value === UserRoles.COMPANY)) {
    buttons.push(
      <Button
        startIcon={UserRolesIcon.COMPANY}
        key={UserRoles.COMPANY}
        variant="contained"
        color="primary"
        onClick={() => handleRedirect(ROUTES.PAINEL.COMPANY)}
        sx={{ ...buttonSx }}
        aria-label="Acessar Painel Empresa"
      >
        Painel Empresa
      </Button>,
    )
  }

  if (profiles.some((profile) => profile.role.value === UserRoles.FUNTRAB)) {
    buttons.push(
      <Button
        startIcon={UserRolesIcon.FUNTRAB}
        key={UserRoles.FUNTRAB}
        variant="contained"
        color="primary"
        onClick={() => handleRedirect(ROUTES.PAINEL.FUNTRAB)}
        sx={{ ...buttonSx }}
        aria-label="Acessar Painel FUNTRAB"
      >
        Painel FUNTRAB
      </Button>,
    )
  }

  if (profiles.some((profile) => profile.role.value === UserRoles.SEMADESC)) {
    buttons.push(
      <Button
        startIcon={UserRolesIcon.SEMADESC}
        key={UserRoles.SEMADESC}
        variant="contained"
        color="primary"
        onClick={() => handleRedirect(ROUTES.PAINEL.SEMADESC)}
        sx={{ ...buttonSx }}
        aria-label="Acessar Painel SEMADESC"
      >
        Painel SEMADESC
      </Button>,
    )
  }

  return <Box sx={{ display: "flex", gap: 1, ...sx }}>{buttons}</Box>
}
